<template>
	<div class="add_update_role">
		<jy-dialog
			type="table"
			:title="title"
			:visible.sync="dialogVisible"
			custom-class="dialog_table"
			:width="global.dialogTabelWidth"
		>
			<div>
				<jy-query ref="form" :model="form">
					<jy-query-item prop="goType" label="方向:">
						<el-select v-model="form.goType" placeholder="请选择">
							<el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"> </el-option>
						</el-select>
					</jy-query-item>
					<jy-query-item prop="cName" label="站点名称:">
						<el-input placeholder="请输入" v-model="form.cName"></el-input>
					</jy-query-item>
					<template slot="search">
						<el-button type="primary" @click="oncheck">查询</el-button>
						<el-button type="primary" plain @click="resetForm('form')">重置</el-button>
						<!-- <el-row>
							<el-button type="primary" @click="onexport" v-if="isExport">导出</el-button>
						</el-row> -->
					</template>
					<template slot="operate">
						<el-button type="primary" @click="onexport" v-if="isExport">导出</el-button>
					</template>
				</jy-query>
				<jy-table max-height="595" :data="dataList">
					<!-- <jy-table-column type="index" label="序号" width="60"></jy-table-column> -->
					<jy-table-column prop="goType" label="方向">
						<template slot-scope="scope">{{
							scope.row.goType == "1" ? "上行" : scope.row.goType == "2" ? "下行" : "环形"
						}}</template>
					</jy-table-column>
					<jy-table-column prop="siteNu" label="站点编码"></jy-table-column>
					<jy-table-column prop="cName" label="站点名称"></jy-table-column>
					<jy-table-column prop="lng" label="经度"></jy-table-column>
					<!-- <jy-table-column prop="lngFlag" label="经度标识"></jy-table-column> -->
					<jy-table-column prop="lat" label="纬度"></jy-table-column>
					<!-- <jy-table-column prop="latFlag" label="纬度标识"></jy-table-column>
                    <jy-table-column prop="angle" label="方向角"></jy-table-column>
                    <jy-table-column prop="spdLimit" label="限速值"></jy-table-column> -->
				</jy-table>
				<jy-pagination
					@size-change="handleSizeChange"
					@current-change="handleCurrentChange"
					:current-page="pageIndex"
					:page-size="pageSize"
					:total="total"
				></jy-pagination>
			</div>
			<div slot="footer" class="dialog-footer"></div>
		</jy-dialog>
	</div>
</template>
<script>
import { btnMixins } from "@/common/js/button.mixin";
export default {
	data() {
		return {
			title: "线路站点明细",
			form: {
				routeId: "",
				goType: "",
				cName: ""
			},
			pageSize: 10,
			total: 0,
			pageIndex: 1,
			isExport: false,
			// 列表列数据
			dataList: [],

			options: [
				{
					value: "",
					label: "全部"
				},
				{
					value: "1",
					label: "上行"
				},
				{
					value: "2",
					label: "下行"
				},
				{
					value: "3",
					label: "环形"
				}
			],
			dialogVisible: false
		};
	},
	mixins: [btnMixins],
	watch: {
		dialogVisible(newview) {
			if (!newview) {
				this.resetForm("form");
				this.form.routeId = "";
			}
		}
	},
	methods: {
		init(option) {
			console.log(option);
			this.form.routeId = option.routeId;
			this.dialogVisible = true;
			if (option.export == true) {
				this.isExport = true;
			} else {
				this.isExport = false;
			}
			this.getList();
		},
		getList() {
			let option = {
				...this.form,
				pageIndex: this.pageIndex,
				pageSize: this.pageSize
			};
			let url = "/route/site/querySiteDetail";
			// console.log(option)
			this.$http.post(url, option).then(res => {
				let list = res.detail.list.map(item => {
					return {
						...item,
						...item.routeSite,
						...item.siteInfo
					};
				});
				this.dataList = list;
				this.total = res.detail.total;
			});
		},
		// 查询表格
		oncheck() {
			this.pageIndex = 1;
			this.getList();
			console.log("更新");
		},
		// 重置
		resetForm(formName) {
			this.$refs[formName].resetFields();
			this.oncheck();
		},
		// 分页
		handleSizeChange(val) {
			this.pageSize = val;
			this.getList();
		},
		handleCurrentChange(val) {
			this.pageIndex = val;
			this.getList();
		},
		// 导出
		onexport() {
			console.log("导出");
			let url = "/route/site/exportSiteDetail";
			let params = {
				goType: this.form.goType,
				cName: this.form.cName,
				routeId: this.form.routeId
			};
			console.log(params);
			this.$http.post(url, params, { isExcel: true }).then(res => {
				if (res.status == 200) {
					this.$util.funDownload(res.data, "站点明细信息");
				} else {
					this.$message({
						message: "导出失败",
						type: "warning"
					});
				}
			});
		}
	}
};
</script>
